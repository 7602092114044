input[type=text] {
    box-sizing: border-box;
    font-size: 12px;
    margin: 8px 0;
    width: 100%;
}
span {
    font-size: 12px;
}

select{
    font-size: 12px !important;

}

.MuiSelect-select.MuiSelect-outlined.MuiSelect-multiple.MuiInputBase-input.MuiOutlinedInput-input {
    font-size: 12px;
}

.mui-datepicker {
    --mui-datepicker-font-size: 16px;
}

.pace-report-table-header > th {
    color: #677C9F;
}