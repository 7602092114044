input::placeholder {
    font-size: 14px;
}

input {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
}

input[type='email']{
    font-size: 14px;
    margin-top: 8px;
    width: 100%;
    border-radius: 4px;
    padding: 12px;
}

button{
    font-size: 14px;
}
