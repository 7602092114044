.apply{
    background-color: #0079FF;
    color: #fff;
    width: 95px;
    height: 40px;
    border-radius: 999px;
}

.cancel{
    border-width: 2px;
    border-color: #0079FF;
    border-radius: 999px;
    color: #0079FF;
    width: 95px;
    height: 40px;
}
.apply:disabled{
    background-color: #EAEEF6;
    color: #677C9F;
}

.transaction-report-table-header > th {
    color: #677C9F;
}