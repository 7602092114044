.copy{
    border-color: #0079FF;
}
.copy:disabled{
    border-color: #EAEEF6;
}

.freeze{
    background-color: #0079FF;
}

.freeze:disabled{
    background-color: #EAEEF6;
}

.MuiToggleButton-primary{
    color: #0079FF;
}
.MuiDateTimePickerTabs-root::selection{
    color: #0079FF;
}

.delete{
    border-color: #D80027 !important;
}

@media (max-width: 1023px) {
    .sales-performance-card.tremor-Card-root {
        box-shadow: none !important;

    }
}

