.tableLoading-overlay {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    top: 0;
    left: 0;
}

.tableLoading-popup {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 20px;
    border-radius: 5px;
}

.tableLoadingBar-cover {
    width: 10rem;
    height: 10px;
    overflow: hidden;
    margin-bottom: 5px;
}

.tableLoading-bar {
    height: 100%;
    width: 0;
    background: #0079FF;
    animation: fill 20s ease-in-out forwards;
}

@keyframes fill {
    to {
        width: 100%;
    }
}
