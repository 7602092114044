:root {
    --close-color: #ACBFDF; 
    --border-color: #EAEEF6;
}
body.dark {
    --close-color: #495D80; 
    --border-color: #273349;
}

.btn {
    border: 1px solid rgba(25, 118, 210, 0.5) !important;
    color: #1976d2 !important;
}

.btn.btn--primary {
    color: #fff !important;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    border: none;
}

.btn.btn--primary:hover {
    text-decoration: none !important;
    --tw-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12) !important;
    --tw-shadow-colored: 0px 2px 4px -1px var(--tw-shadow-color), 0px 4px 5px 0px var(--tw-shadow-color), 0px 1px 10px 0px var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
    --tw-bg-opacity: 1 !important;
    background-color: rgb(42 82 190) !important;
}



.uploader a:hover:not(:disabled):not(.disabled) {
    color: var(--close-color) !important;
}

.uploader a, .uploader a:hover, .uploader a:active, .uploader a:focus {
    text-decoration: none;
}

.uploader a:hover:not(:disabled):not(.disabled) {
    color: #377dff !important;
}

.uploader__submitted-file__inner {
    border: 1px solid var(--border-color);
    border-radius: 4px;
}

.ReactCrop__crop-selection {
    outline: 3px solid #0079FF !important;
    animation: none !important;
    background-image: none !important;
}

.ReactCrop__drag-handle {
    background-color: #0079FF !important;
    border: none !important;
}