.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}
.loading-popup {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background: rgba(204, 198, 198, 0);
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.loading-text {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.loadingBar-cover {
    width: 10rem;
    height: 10px;
    background: #EAEEF6;
    overflow: hidden;
    margin-bottom: 5px;
}

.loading-bar {
    height: 100%;
    width: 0;
    background: #0079FF;
    animation: fill 5s ease-in-out forwards;
}

@keyframes fill {
    to {
        width: 100%;
    }
}
