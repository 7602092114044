input[type="text"] {
  box-sizing: border-box;
  font-size: 12px;
  margin: 8px 0;
  width: 100%;
}
span {
  font-size: 12px;
}

select {
  font-size: 12px !important;
}

.MuiSelect-select.MuiSelect-outlined.MuiSelect-multiple.MuiInputBase-input.MuiOutlinedInput-input {
  font-size: 14px;
}

.mui-datepicker {
  --mui-datepicker-font-size: 16px;
}

.affiliate_scrollbar::-webkit-scrollbar {
  width: 6px;
}

.affiliate_scrollbar::-webkit-scrollbar-thumb {
  background-color: #677c9f;
  border-radius: 9999px;
}

/* For Firefox */
.affiliate_scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #677c9f transparent;
}
