.css-2ky8oz-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23) !important;
}

.css-2ky8oz-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.87) !important;
}

:root {
    --border-dropdown: none;
}

body.dark {
    --border-dropdown:  1px solid #273349;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    background-image: none !important;
    border: var(--border-dropdown)  !important;
    margin-top: 4px;
}

ul:has(li.datarange-item-mui) {
    min-height: 100% !important;
  }


.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #0079FF !important;
}


.MuiButtonBase-root.Mui-selected svg {
    color: #0079FF !important;
}

@media (max-width: 1023px) {
    .MuiDialog-paper {
        width: 100% !important;
        max-width: 100% !important;
        height: 100% !important;
        max-height: 100% !important;
        margin: 0 !important;
    }

    .MuiPickersLayout-root {
        height: 100% !important;
    }

    .MuiDateCalendar-root {
        width: 100% !important;
        height: 100% !important;
        max-height: unset !important;
    }

    .MuiPickersFadeTransitionGroup-root {
        height: 100% !important;
    }

    .MuiPickersFadeTransitionGroup-root>div {
        height: 100% !important;
    }

    .MuiDayCalendar-root {
        padding: 0 15px !important;
        height: 100% !important;
    }

    .MuiPickersSlideTransition-root {
        overflow: hidden;
        height: 100% !important;
    }

    .MuiDayCalendar-header {
        justify-content: space-between !important;
        padding: 0 15px !important;
    }

    .MuiDayCalendar-monthContainer {
        height: 70% !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: space-between !important;
    }

    .MuiDayCalendar-weekContainer {
        justify-content: space-between !important;
        padding: 0 15px !important;

    }

    .MuiYearCalendar-root {
        height: 100% !important;
        width: 100% !important;
        max-height: 400px !important;
    }

    .MuiMonthCalendar-root {
        height: 100% !important;
        width: 100% !important;
    }

    .MuiTimeClock-root {
        height: 100% !important;
        width: 100% !important;
        max-width: 380px !important;
    }
}

.MuiTabs-root.MuiDateTimePickerTabs-root {
    box-shadow: none !important;
}

.MuiTabs-indicator {
    background-color: #0079FF !important;
}

.MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
    font-size: 14px !important;
}

.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin {
    font-size: 14px !important;   
}

.MuiClock-pin, .MuiClockPointer-root, .MuiClockPointer-thumb {
    background-color: #0079FF !important;
}

.MuiClockPointer-thumb {
    border: 16px solid #0079FF !important;
}

.css-l9lqcv-MuiButtonBase-root-MuiIconButton-root-MuiClock-amButton {
    background-color: #0079FF !important;
    color: #EBEFF7 !important;
}

.css-id6y46-MuiButtonBase-root-MuiIconButton-root-MuiClock-pmButton {
    background-color: #0079FF !important;
    color: #EBEFF7 !important;
}

.css-iv2bz4-MuiClockNumber-root.Mui-selected  {
    color: #EBEFF7 !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.Mui-focused.mui-select-daterange-picker {
    border: none !important;
}

.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
    border-radius: 20px !important;
}
