.left-panel {
  background: rgb(8 26 43);
  width: 26%;
  min-width: 320px;
  z-index: -1;
}

.form-panel {
  position: relative;
  background: white;
  height: inherit;
}
.sub-head {
  padding-top: 50px;
  font-size: 24px;
  font-weight: 700;
  font-family: Inter, sans-serif;
}
.sub-description {
  padding-top: 12px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  font-family: Inter, sans-serif;
  margin-bottom: 32px;
}
.input {
  width: 540px;
  height: 57px;
  border: solid 1px #acbfdf;
  border-radius: 5px;
  margin-top: 8px;
  margin-bottom: 12px;
  font-size: 16px;
}
.input::placeholder {
  font-size: 16px;
}
.input:focus {
  outline: none;
  border: solid 1px #0079ff;
}
input[type="text"],
input[type="password"] {
  width: 100%;
  margin: 8px 0;
  box-sizing: border-box;
  font-size: 16px;
}
.checkbox {
  width: 20px;
  height: 20px;
}
.login {
  background: #0079ff;
  color: white;
}
.login:disabled {
  background: #eaeef6;
  color: #677c9f;
}
.icon {
  position: relative;
  width: 20px;
  right: -431px;
  top: -48px;
  cursor: pointer;
}

