@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    /* Safari-specific styles here */
    .safari-relative {
      position: relative;
    }
  }
}

/* Safari 7.1+ */
_::-webkit-full-page-media,
_:future,
:root .safari-relative {
  position: relative;
}

/* Safari 10.1+ */

@media not all and (min-resolution: 0.001dpcm) {
  @media {
    .safari-relative {
      position: relative;
    }
  }
}

/* Safari 10.1+ (alternate method) */

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .safari-relative {
      position: relative;
    }
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .safari-relative {
      position: relative;
    }
  }
}

html>>* .safari-relative {
  position: relative;
}

/* Safari 10.0 (not 10.1) */

_::-webkit-:host:not(:root:root),
.safari-relative {
  position: relative;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (not (stroke-color: transparent)) {
    .safari-relative {
      position: relative;
    }
  }
}

_::-webkit-:-webkit-full-screen:host:not(:root:root),
.safari-relative {
  position: relative;
}

@supports (-webkit-hyphens: none) {
  .safari-relative {
    position: relative;
  }
}

/* Safari 9+, < 13.1 */

@supports (-webkit-marquee-repetition: infinite) and (object-fit: fill) {
  .safari-relative {
    position: relative;
  }
}

/* Safari 9.0-10.0 (not 10.1) */

_::-webkit-:not(:root:root),
.safari-relative {
  position: relative;
}

/* ////////////////////////////////////////////////////////////////////////////// */

/* Detect Safari and apply left: 0 */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .safari-left-zero {
      left: 0 !important;
    }
    .safari-hidden {
      display: none !important;
    }
  }
}

/* Safari 7.1+ */
_::-webkit-full-page-media,
_:future,
:root .safari-left-zero {
  left: 0 !important;
}

/* Safari 7.1+ */
_::-webkit-full-page-media,
_:future,
:root .safari-hidden {
  display: none !important;
}

/* Safari 10.1+ */
@media not all and (min-resolution: 0.001dpcm) {
  @media {
    .safari-left-zero {
      left: 0 !important;
    }
    .safari-hidden {
      display: none !important;
    }
  }
}

/* Safari 10.1+ (alternate method) */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .safari-left-zero {
      left: 0 !important;
    }
    .safari-hidden {
      display: none !important;
    }
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .safari-left-zero {
      left: 0 !important;
    }
    .safari-hidden {
      display: none !important;
    }
  }
}

html>>* .safari-left-zero {
  left: 0 !important;
}

html>>* .safari-hidden {
  display: none !important;
}

/* Safari 10.0 (not 10.1) */
_::-webkit-:host:not(:root:root),
.safari-left-zero {
  left: 0 !important;
}

_::-webkit-:host:not(:root:root),
.safari-hidden {
  display: none !important;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (not (stroke-color: transparent)) {
    .safari-left-zero {
      left: 0 !important;
    }
    .safari-hidden {
      display: none !important;
    }
  }
}

_::-webkit-:-webkit-full-screen:host:not(:root:root),
.safari-left-zero {
  left: 0 !important;
}

_::-webkit-:-webkit-full-screen:host:not(:root:root),
.safari-hidden {
  display: none !important;
}

@supports (-webkit-hyphens: none) {
  .safari-left-zero {
    left: 0 !important;
  }

  .safari-hidden {
    display: none !important;
  }
}

/* Safari 9+, < 13.1 */
@supports (-webkit-marquee-repetition: infinite) and (object-fit: fill) {
  .safari-left-zero {
    left: 0 !important;
  }
  .safari-hidden {
    display: none !important;
  }
}

/* Safari 9.0-10.0 (not 10.1) */
_::-webkit-:not(:root:root),
.safari-left-zero {
  left: 0 !important;
}

_::-webkit-:not(:root:root),
.safari-hidden {
  display: none !important;
}

/* ////////////////////////////////////////// */
/* Detect Safari and apply left: 0 */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .safari-align-left {
      text-align: left !important;
    }
  }
}

/* Safari 7.1+ */
_::-webkit-full-page-media,
_:future,
:root .safari-align-left {
  text-align: left !important;
}

/* Safari 10.1+ */
@media not all and (min-resolution: 0.001dpcm) {
  @media {
    .safari-align-left {
      text-align: left !important;
    }
  }
}

/* Safari 10.1+ (alternate method) */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .safari-align-left {
      text-align: left !important;
    }
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .safari-align-left {
      text-align: left !important;
    }
  }
}

html>>* .safari-align-left {
  text-align: left !important;
}

/* Safari 10.0 (not 10.1) */
_::-webkit-:host:not(:root:root),
.safari-align-left {
  text-align: left !important;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (not (stroke-color: transparent)) {
    .safari-align-left {
      text-align: left !important;
    }
  }
}

_::-webkit-:-webkit-full-screen:host:not(:root:root),
.safari-align-left {
  text-align: left !important;
}

@supports (-webkit-hyphens: none) {
  .safari-align-left {
    text-align: left !important;
  }
}

/* Safari 9+, < 13.1 */
@supports (-webkit-marquee-repetition: infinite) and (object-fit: fill) {
  .safari-align-left {
    text-align: left !important;
  }
}

/* Safari 9.0-10.0 (not 10.1) */
_::-webkit-:not(:root:root),
.safari-align-left {
  text-align: left !important;
}

/* ////////////////////////////////////////// */
