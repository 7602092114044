@import url('./css/safari-browser.css');

html, body, #app, #app>div {
    font-family: Inter, sans-serif;
}

*::-webkit-scrollbar {
    width: 6px;
  }
  
*::-webkit-scrollbar-thumb {
    background-color: #677c9f;
    border-radius: 9999px;
  }
  
  /* For Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #677c9f transparent;
  }

  input[type="text"],
input[type="password"] {
  padding-top: 12px;
  padding-bottom: 12px;
}

.recharts-wrapper {
  cursor: pointer !important;
}
.recharts-tooltip-wrapper {
  z-index: 9999 !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  box-shadow: 0px 0px 0px 1px #0000000D,0px 4px 6px -2px #0000000D,0px 10px 15px -3px #0000001A;
}

ul.MuiMenu-list {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  max-height: 330px !important;
  max-width: 300px !important;
}

input[type="search"]::-webkit-search-cancel-button {
  cursor: pointer;
}

input[type="search"]::-moz-search-clear-button {
  cursor: pointer;
}

input[type="search"]::-ms-clear {
  cursor: pointer;
}

