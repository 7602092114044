.Toastify__toast--error {
    width: 320px;
    font-size: 14px;
    color: #e74c3c;
    min-height: 44px;
    border-left: 4px solid #e74c3c;
    border-radius: 7px;
    background: #ffe5e3;
    padding-left: 10px;
}

.Toastify__toast--success {
    width: 320px;
    font-size: 14px;
    color: #0F766E;
    min-height: 44px;
    border-left: 4px solid #AA0000;
    border-radius: 7px;
    background: #DEFCF5;
    padding-left: 10px;
}

.Toastify__toast--warning {
    width: 320px;
    font-size: 14px;
    color: #0F766E;
    min-height: 44px;
    border-left: 4px solid #AA0000;
    border-radius: 7px;
    background: #DEFCF5;
    padding-left: 10px;
}

.Toastify__toast--success::before{
    content: url("../../../assets/toastIcons/ToastSuccess.svg");
    position:relative;
    z-index:100000;
    left:2px;
    top:8px;
    padding-right: 10px;
}

.Toastify__toast--error::before {
    content: url("../../../assets/toastIcons/ToastFailure.svg");
    position:relative;
    z-index:100000;
    left:2px;
    top:8px;
    padding-right: 10px;
}

.Toastify__toast--warning::before {
    content: url("../../../assets/toastIcons/ToastWarning.svg");
    position:relative;
    z-index:100000;
    left:2px;
    top:8px;
    padding-right: 10px;
}

@media (max-width: 480px) {
    .Toastify__toast--error {
        width: 100%;
        border-left: none;
        border-radius: 0px;
    }

    .Toastify__toast--success {
        width: 100%;
        border-left: none;
        border-radius: 0px;
    }
}

:root {
    --content-success: url("../../../assets/toastIcons/ToastSuccess.svg");
    --content-failure: url("../../../assets/toastIcons/ToastFailure.svg");
    --content-warning: url("../../../assets/toastIcons/ToastWarning.svg");
    --border-color-success: #0F766E;
    --border-color-failure: #E74C3C;
    --border-color-warning: #FFC107;
}

body.dark {
    --content-success: url("../../../assets/toastIcons/ToastSuccessDark.svg");
    --content-failure: url("../../../assets/toastIcons/ToastFailureDark.svg");
    --content-warning: url("../../../assets/toastIcons/ToastWarning.svg");
    --border-color-success: #0EC539;
    --border-color-failure: #FDB5AE;
}

.Toastify__toast--success::before  {
    content: var(--content-success);
}

.Toastify__toast--success {
    border-left: 4px solid var(--border-color-success) !important;
}

.Toastify__toast--error {
    border-left: 4px solid var(--border-color-failure);
}

.Toastify__toast--error::before  {
    content: var(--content-failure);
}

.Toastify__toast--warning::before  {
    content: var(--content-warning);
}

.Toastify__toast--warning {
    border-left: 4px solid var(--border-color-warning);
}