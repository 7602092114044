td.is-sticky {
    position: sticky;
    left: 0;
    background-color: inherit;
    z-index: 1;
}

td.is-sticky-dark {
    position: sticky;
    left: 0;
    background-color: inherit;
    z-index: 1;
}